<template>
  <div class="absolute left-0 right-0 w-full md:w-auto md:mx-6 flex flex-col md:gap-2 z-50 xl:mt-6">
    <TransitionGroup>
      <div v-for="notification in useNotifications().notifications.value" :key="notification.id">
        <div class="md:rounded shadow px-4 py-2 flex justify-between gap-4" :class="notification.classes">
          <div class="flex gap-4">
            <div class="text-white opacity-50"><i :class="notification.icon"></i></div>
            <div class="flex flex-col gap-1">
              <div class="text-white">{{ $t(notification.message) }}</div>
              <div v-if="notification.detail" class="text-white opacity-70 text-xs">
                {{notification.detail}}
              </div>
            </div>
          </div>
          <div class="text-white opacity-50 justify-self-end hover:opacity-100 transition cursor-pointer"
               @click="useNotifications().remove(notification.id)"><i class="fal fa-xmark"></i></div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>
<script setup lang="ts">
</script>